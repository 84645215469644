import React from "react";
import ReactDOM from "react-dom";

import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "./styles.css";
import {filterValidPredictions } from "./digits_reader.js";
import {aspect_ratio } from "./digits_reader.js";
import {returnWeight } from "./digits_reader.js";
import {lobstersize } from "./digits_reader.js";
import packageJson from '../package.json'


class App extends React.Component {

  constructor(props) {
    super(props)
  
    this.videoRef = React.createRef();
    this.canvasRef = React.createRef();
    this.fps = 6;
    // available to global
    window.detectFrame = this.detectFrame
  }

  componentDidMount() {

    console.log('>>> running version: ', packageJson.version)

    var videoSelect = document.querySelector("select#videoSource");
    videoSelect.onchange = this.cameraChangeHandler;

    this.getStream()
      .then(this.getDevices)
      .then(this.gotDevices);
    
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user"
          }
        })
        .then(stream => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });
      
      var modelPromise = cocoSsd.load();
      console.log('>>> loading default model...')
      modelPromise.then(res => {
        console.log('>>> model cached & loaded!')
        window.model = res // save the current model for next use
        let setting = document.querySelector('#setting')
        setting.innerHTML = 'Settings(Click to open)'
      })

      const select = document.getElementById("base_model");
      select.onchange = this.modelSwitchHandler
      
      Promise.all([modelPromise, webCamPromise])
        .then(values => {
          console.log('>>> promise all reached!')
          window.runningAnim = true // open flag
          this.detectFrame(this.videoRef.current, window.model);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  modelSwitchHandler = event => {
    window.runningAnim = false; // stop prediction

    var model = event.srcElement.value

    let setting = document.querySelector('#setting')
    setting.innerHTML = 'Settings( Loading model... )'
    console.log('>>> loading model: ', model)

    var modelPromise = cocoSsd.load({
      base: model
    });
    modelPromise.then(res => {
      window.model = res // cache again
      let setting = document.querySelector('#setting')
      setting.innerHTML = 'Settings(Click to open)'
      console.log('>>> new model loaded, restart predtion!')
      window.runningAnim = true
      this.detectFrame(this.videoRef.current, window.model);
    })  
  };

  getStream = () => {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    var videoSelect = document.querySelector("select#videoSource");
    const videoSource = videoSelect.value;
    const constraints = {
      video: { deviceId: videoSource ? { exact: videoSource } : undefined }
    };
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this.gotStream)
      .catch(this.handleError);
  }

  getDevices = () => {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  // cache video stream and display video
  gotStream = (stream) => {
    console.log('>>> 1. got stream ...')
    window.stream = stream; // make stream available to console
    var videoElement = document.querySelector("video");
    // attach the stream to video element
    videoElement.srcObject = stream;
    console.log(">>> Video is on");
  }

  gotDevices = (deviceInfos) => {
    console.log('>>> 2. got devices to build options...')
    window.deviceInfos = deviceInfos; // make available to console
    // console.log("Available input and output devices:", deviceInfos);
    var videoSelect = document.querySelector("select#videoSource");
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement("option");
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === "videoinput") {
        option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        videoSelect.appendChild(option);
      }
    }
    videoSelect.selectedIndex = [...videoSelect.options].findIndex(
      option => option.text === window.stream.getVideoTracks()[0].label
    );
  }

  handleError = (error) => {
    console.error("Error: ", error);
  }

  detectFrame = (video, model) => {
    if(!window.runningAnim) return // check the flag

    model.detect(video).then(predictions => {
      this.renderPredictions(predictions);

      setTimeout(function() {
        window.requestAnimationFrame(() => {
          this.detectFrame(video, model); // call myself
        });
      }, 1000 / this.fps) // better performance
    });
  };

  cameraChangeHandler = () => {
    console.log('>>> switching camera...')
    var videoElement = document.querySelector("video")
    this.stopDetectFrame(videoElement) // stop first
    // listen video available
    videoElement.onloadedmetadata = () => {
      console.log('>>> video ready, restart prediction!')
      window.runningAnim = true // open flag
      this.detectFrame(this.videoRef.current, window.model) // restart prediction
    }
    this.getStream() // request camera again
  }

  stopDetectFrame = (video) => {
    if(!video || !window.stream) return
    // stop animation
    window.runningAnim = false;
    // stop video steam
    window.stream.getTracks().forEach(track => track.stop());
    // close video
    video.pause();
    video.srcObject = null;
    console.log(">>> Video is off");
  }

  renderPredictions = predictions => {
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // console.log(ctx.canvas.width);
    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    predictions
      .filter(prediction => 
        (prediction.class != "Bad Number" &&
         aspect_ratio(prediction.bbox) &&
         prediction.score > 0.8 && 
         prediction.class !== "Digital Screen")
      )
      .forEach(prediction => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];
      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 4;
      ctx.strokeRect(x, y, width, height);
      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const label_text = prediction.class + " " + prediction.score.toFixed(2);
      const textWidth = ctx.measureText(label_text).width;
      const textHeight = parseInt(font, 10); // base 10
      ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      ctx.fillText(label_text, x, y);
    });
    
    // extracting predictions
    
    const result = filterValidPredictions(predictions);
    // call set reading function
    if (result !== ""){
      returnWeight(result);
    }
    console.log('got:', result);
  };

  render() {
    return (
      <div id="camera1">
        <video
          className="size"
          autoPlay
          playsInline
          muted
          ref={this.videoRef}
          width="640"
          height="480"
        />
        <canvas
          className="size"
          ref={this.canvasRef}
          width="640"
          height="480"
        />
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);


