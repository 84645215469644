const num_of_precision = 2;
const num_of_matches = 2;
var last_reading = 0.0;
var last_digits_pos = [];

// digits reading model handling
export function check_overlap(box1, box2) {
  // box[x,y,w,h]
  return (((box1[0] >= box2[0] && box1[0] <= box2[0]+box2[2])
    || (box1[0]+box1[2] >= box2[0] && box1[0]+box1[2] <= box2[0]+box2[2]))
  && ((box1[1] >= box2[1] && box1[1] <= box2[1]+box2[3])
    || (box1[1]+box1[3] >= box2[1] && box1[1]+box1[3] <= box2[1]+box2[3])))
}

export function aspect_ratio(box) {
  // box[x,y,w,h]
  return (box[3] >= box[2])
}

export function filterValidPredictions(predictions) {
  var result = "";

  // const screen_box = screen_boxes[0]
  const good_numbers = predictions
      .filter(prediction => 
        (prediction.class !== "Bad Number" &&
         aspect_ratio(prediction.bbox) &&
         prediction.score > 0.8 && 
         prediction.class !== "Digital Screen")
      )
  // sort from right to left on x axis
      .sort(function(a,b) { return a.bbox[0] - b.bbox[0]})
  
  var missing_digits = false;
  // get number starting from the lowest digit    
  //get second digits after dot
  var digit_count = good_numbers.length;
  for (var i = good_numbers.length - 1; i>=0 ; i--){
    result = good_numbers[i].class + result;
    if (i === good_numbers.length - num_of_precision){
      result = "." + result;
    };
    if (i > 0 && good_numbers[i].bbox[0]-good_numbers[i-1].bbox[0] > good_numbers[i].bbox[2]*1.5){
      missing_digits = true;
    };
  }

  // check completeness of reading
  if (
      // starting with .
      result.indexOf(".") === 0 || 
      // less digit count than precision
      digit_count <= num_of_precision || 
      // distance between digits varies too much
      missing_digits
     ){
    result = "";
  } else {
    const lst_digit_x = good_numbers[good_numbers.length-1].bbox[0];
    const lst_digit_w = good_numbers[good_numbers.length-1].bbox[2];
    last_digits_pos.push(lst_digit_x);
    if (last_digits_pos.length > 10){
      last_digits_pos.shift();
    }
    const sum_last_digits_pos = last_digits_pos.reduce((a, b) => a + b, 0);
    const avg_last_digits_pos = (sum_last_digits_pos / last_digits_pos.length) || 0;
    
    if (
        // only update result when reading matches.
        //parseFloat(result) === last_reading && 
        // check if we missed last digits
        Math.abs(lst_digit_x-avg_last_digits_pos) < lst_digit_w/2
       ){
      console.log("found a stable reading")
    } else {
      last_reading = parseFloat(result);
      result = "";
    } 
  }
  
  // need to add logic to check missing digits using avg_number_width

  return result;
}

export function returnWeight(result){
    //Show Lobster Size
    var reverse =document.getElementById("reverse").value;
      //reverse mode
    if(reverse == '1'){
      lobstersize(result,"1");
    }
    //Normal mode
    //result is current reading 
    else if (reverse == '0'){
      lobstersize(result,"0");
    }
    document.getElementById("reading_1").value = result;
    document.getElementById("reading_1").onchange();
  
}

//Calculate the weight of the lobster according to the change of the weighing value 
//before and after, and display the corresponding information.
export function lobstersize(result,mode_type){
    var weight = 0.0;
    if(mode_type =="1"){
      var scale = document.getElementById("previou_weight").value;
      if(parseFloat(scale)<0.5){
        weight =parseFloat(result);
      }else{
        weight = parseFloat(scale) - parseFloat(result);
      }
      document.getElementById("previou_weight").value = result;
    }
    if(mode_type =="0"){
      weight = parseFloat(result);
    }
    document.getElementById("lob_weight").value = weight;
    if(parseFloat(weight)<= 0.5  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#ED008C';
      document.getElementById("sizediv").innerHTML='N/A';
    }
    if( parseFloat(weight)>0.5 & parseFloat(weight)<= 1.2  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#ED008C';
      document.getElementById("sizediv").innerHTML='Small CHIX';
    }
    if( parseFloat(weight)>1.2 & parseFloat(weight)<= 1.25  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#ED008C';
      document.getElementById("sizediv").innerHTML='Lagre CHIX';
    }
    if( parseFloat(weight)>1.25 & parseFloat(weight)<= 1.45  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#00A885';
      document.getElementById("sizediv").innerHTML='Quarter';
    }
    if( parseFloat(weight)>1.45 & parseFloat(weight)< 1.7  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#00A885';
      document.getElementById("sizediv").innerHTML='Halves';
    }
    if( parseFloat(weight)>=1.7 & parseFloat(weight)< 1.95  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#02A5E8';
      document.getElementById("sizediv").innerHTML='Select';
    }
    if( parseFloat(weight)>=1.95 & parseFloat(weight)< 2.5  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#5F2C91';
      document.getElementById("sizediv").innerHTML='S D';
    }
    if( parseFloat(weight)>=2.5 & parseFloat(weight)< 3  ){
      document.getElementById("sizediv").style.background='#5F2C91';
      document.getElementById("sizediv").style.color='#FFFFFF';
      document.getElementById("sizediv").innerHTML='L D';
    }
    if( parseFloat(weight)>=3 & parseFloat(weight)<= 4 ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#FFE01B';
      document.getElementById("sizediv").innerHTML='3 - 4';
    }
    if( parseFloat(weight)>4 & parseFloat(weight)<= 6  ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#01A99C';
      document.getElementById("sizediv").innerHTML='4 - 6'
    }
    if( parseFloat(weight)>6 & parseFloat(weight)<= 10){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#231F20';
      document.getElementById("sizediv").innerHTML='6 - 10'
    }
    if( parseFloat(weight)>10 ){
      document.getElementById("sizediv").style.background='#FFFFFF';
      document.getElementById("sizediv").style.color='#231F20';
      document.getElementById("sizediv").innerHTML='10 + '
    }
    if( parseFloat(weight)<0){
      //document.getElementById("sizediv").style.background='#FFFFFF';
      //document.getElementById("sizediv").style.color='red';
      //document.getElementById("sizediv").innerHTML='ERROR'
    }
  
}
